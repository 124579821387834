<template>
  <div id="AddUser" class="row mx-0">
    <div class="row mx-0">
      <div id="header" class="col-sm-12 col-md-12 color-bg-4">
        <Header
                :name="user.fullname"
                /> 
      </div>
      <div class="col-sm-12 col-md-12">
        <div id="content" class="row color-bg-4">
           <div id="" class="">
             <SideMenu :indexsec="12"/>
          </div>
          <div id="stn-edituser" class="stn_edituserAdd">
           
            <form
              @submit.prevent="
                submit(
                  liga_form,
                 name_form,
                 file1,
                 slide_form
                )
              "
            >
            <div class="div_EProduct">
              <p>AGREGAR TORNEO</p>
              </div>


              <div class="row mx-0 row-one">
                <div class="EPF1_C1">
                  <label class="labelN color-1" for="inputN">Nombre</label>
                  <b-form-input
                   pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]{2,254}"
                    v-model="name_form"
                    required
                    id="inputN"
                    type="text"
                  />
                </div>

                <div class="div-image QUITLEFT">
                  <label class="labelImg color-1" for="inputImg">Logo</label>
                  <b-form-file
                    v-model="file1"
                    id="inputImg"
                    required
                    type="file"
                    name="inputImg"
                    :placeholder="'Subir imagen'"
                  />
                </div>

                 
                
              </div>
               <div class="row mx-0 row-two">
               
                <div class="EPF1_C1">
                    <label class="labelR color-1" for="inputR">Liga</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{liga_name}}</span></div> 
                      </template>

                       <div v-for="(item, key) in this.ligas" :key="key">
                          <b-dropdown-item @click="changeLiga(item.id, item.name)">
                            {{item.name}}
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>
                   <div class="">
                  <label class="labelR color-1" for="inputR">TORNEO PRINCIPAL</label>
                  <b-dropdown>
                    <template #button-content>
                      <div class="row"> <span class="drop-text">{{slide_form}}</span></div>
                    </template>

                    <b-dropdown-item @click="changeSlide('ACTIVO')">
                      ACTIVO
                    </b-dropdown-item>

                      <b-dropdown-item @click="changeSlide('INACTIVO')">
                      INACTIVO
                    </b-dropdown-item>
          
                  </b-dropdown>
                </div>
                   
               
                
              </div>

              


           
               <div class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>

              </div>
              <div class="btn-form">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal "
                  type="submit"
                  >GUARDAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">El torneo se guardo correctamente</p>
                  </div>
                  <div class="">
                   
                    <div class="btns-dflx">
                      <div class="btn-void"></div>

                    <b-button class="btn-modal" @click="agregarOtro()"
                      >AÑADIR OTRA</b-button
                    >
                      <b-button class="btn-modal" @click="toEdit()"
                        >EDITAR</b-button
                      >
                    </div>


                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>
        
            
              
            
            
           


  
          
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SideMenu from '../../components/SideMenu';
import logo from '../../assets/cimarrones/logocima_2.png';
import IconSuccess from "../../assets/Vector.png";
import legrafica from "../../assets/Legrafica.png";
import Header from '../../components/Header';

import { mapActions } from "vuex";
export default {
  name: "AddGallery",
    components: {
    Header,
    SideMenu,
  },
  data() {
    return {
   
      
      name_form:"",
      liga_form:"",
      slide_form:"INACTIVO",
      liga_name:"Liga",
      status: "",
      msg: "",
      file1:null,
      logo: logo,
      legrafica: legrafica,
      IconSuccess: IconSuccess,
      added :''

    };
  },async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    },
  created() {
    this.getLigas('Ligas')
    
  },
  computed:{
       ligas() {
          return this.$store.getters["liga/data"];
        },
        url() {
          return this.$store.getters["main/baseURL"];
       },user() {            
            return this.$store.getters['admin/getIdentity'];
        },
  },
  methods: {
   ...mapActions('liga', ['getLigas']),
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    toEdit: function () {
       this.$router.push("/editar/torneo/"+this.added.id).catch((err) => {});
    },
    submit: async function ( id_liga, name, image, slide) {
          this.status=''
          this.msg=''
       
       

         if(image == null){
            this.status='error'
            this.msg='Imagen no subida'
         }else if(id_liga == '' || id_liga == undefined || id_liga==null){
              this.status='error'
              this.msg='Elige una liga.'
         }else{
               if(image.size > 1000000){
                this.status='error'
                this.msg= 'Tamaño de imagen no válido, sube imagenes menores a 1 MB.'
               
                
            }else{
                var data = new  FormData();
              
                  data.append('image', image);
                  data.append('name',name);
                   data.append('slide',slide);
                   data.append('id_liga', String(id_liga) );
                  data.append('_method', 'POST');
                
                      
                  this.status=''
                  this.msg=''
              
                  let result = await this.$store.dispatch("main/addItem",  {option:'tournament', item: data});
                
                  if(result.status=='error'){
                    this.status='error'
                    this.msg= result.message
                  }else{ // success
                      this.content=''
                      this.added = result.added
                      this.showModal() 
                  }

            }
                

        }

        

    },
    agregarOtro: function () {
      this.wait();
    },
    salir: function () {
       this.$router.push("/administrador").catch((err) => {});
    },
    showModal(){
       this.$refs['modal-editar'].show()
    },
     changeLiga: function (value, name) {
     
       this.liga_form = value;
      this.liga_name = name;

    },
    changeSlide: function (value) {
     
       this.slide_form = value;
  

    },
  

  },
};
</script>
